<template>
  <v-dialog :value="value" @input="close" max-width="800px">
    <v-card>

      <!-- Title -->
      <v-card-title>
        <h3>{{$t('subscriptions.editDialog.title')}}</h3>
      </v-card-title>

      <v-card-text>
        <v-row no-gutters>

          <!-- Firstname -->
          <v-col cols="12">
            <v-text-field
              :label="$t('subscriptions.inputs.firstname')"
              outlined
              maxlength="255"
              v-model="form.person.firstname">
            </v-text-field>
          </v-col>

            <!-- Name -->
          <v-col cols="12">
            <v-text-field
                :label="$t('subscriptions.inputs.name')"
                outlined
                maxlength="255"
                v-model="form.person.name">
            </v-text-field>
          </v-col>

          <!-- Plan -->
          <v-col cols="12">
            <v-select
                outlined
                :items="['FREE', 'PREMIUM_WEEKLY', 'PREMIUM_MONTHLY', 'PREMIUM_ANNUALLY']"
                v-model="form.subscription.plan"
                @change="onChangePlan"
                :label="$t('subscriptions.inputs.plan')"
            />
          </v-col>

          <!-- Dates start -->
          <v-col cols="12" class="my-2">
            <InputDatePicker
              :label="$t('subscriptions.inputs.date_start')"
              @onInput="onInputDateStart"
              :value="form.subscription.dates.start"
            />
          </v-col>

          <!-- Dates end -->
          <v-col cols="12" class="my-2">
            <InputDatePicker
              :label="$t('subscriptions.inputs.date_end')"
              @onInput="onInputDateEnd"
              :value="form.subscription.dates.end"
            />
          </v-col>

          <!-- Date next occurrence -->
          <v-col cols="12" class="my-2">
            <InputDatePicker
              :label="$t('subscriptions.inputs.next_occurrence')"
              @onInput="onInputDateOccurrence"
              :value="form.subscription.dates.next_occurrence"
            />
          </v-col>

          <!-- Name customer -->
          <v-col cols="12" class="mt-2">
            <v-text-field
              :label="$t('subscriptions.inputs.billing.customer_name')"
              outlined
              maxlength="100"
              v-model="form.subscription.billing.name">
            </v-text-field>
          </v-col>

          <!-- Email -->
          <v-col cols="12">
            <v-text-field
              :label="$t('subscriptions.inputs.email')"
              outlined
              maxlength="255"
              v-model="form.subscription.billing.email">
            </v-text-field>
          </v-col>

          <!-- Phone -->
          <v-col cols="12">
            <v-text-field
              :label="$t('subscriptions.inputs.phone')"
              outlined
              maxlength="40"
              v-model="form.subscription.billing.phone">
            </v-text-field>
          </v-col>

          <!-- VAT Number -->
          <v-col cols="12">
            <v-text-field
              :label="$t('subscriptions.inputs.billing.vat_number')"
              outlined
              maxlength="40"
              v-model="form.subscription.billing.vat_number">
            </v-text-field>
          </v-col>

          <!-- Service -->
          <v-col cols="12">
            <v-text-field
              :label="$t('subscriptions.inputs.billing.address.service')"
              outlined
              maxlength="40"
              v-model="form.subscription.billing.address.service">
            </v-text-field>
          </v-col>

          <!-- Street -->
          <v-col cols="12">
            <v-text-field
              :label="$t('subscriptions.inputs.billing.address.street')"
              outlined
              maxlength="255"
              v-model="form.subscription.billing.address.street">
            </v-text-field>
          </v-col>

          <!-- Additional Street -->
          <v-col cols="12">
            <v-text-field
              :label="$t('subscriptions.inputs.billing.address.additional_street')"
              outlined
              maxlength="255"
              v-model="form.subscription.billing.address.additional_street">
            </v-text-field>
          </v-col>

          <!-- Locality -->
          <v-col cols="12">
            <v-text-field
              :label="$t('subscriptions.inputs.billing.address.locality')"
              outlined
              maxlength="255"
              v-model="form.subscription.billing.address.locality">
            </v-text-field>
          </v-col>

          <!-- Postal Code -->
          <v-col cols="12">
            <v-text-field
              :label="$t('subscriptions.inputs.billing.address.postal_code')"
              outlined
              maxlength="40"
              v-model="form.subscription.billing.address.postal_code">
            </v-text-field>
          </v-col>

          <!-- Country -->
          <v-col cols="12">
            <SelectCountries
              :multiple="false"
              :value="form.subscription.billing.address.country"
              v-on:onInput="(val) => {form.subscription.billing.address.country = val;}"/>
          </v-col>

          <!-- Lang -->
          <v-col cols="12">
            <v-select
              outlined
              :items="['fr', 'en']"
              v-model="form.preferences.lang"
              :label="$t('subscriptions.inputs.lang')"
            />
          </v-col>

          <!-- Enabled -->
          <v-col cols="12">
            <v-switch
                v-model="form.enabled"
                :label="$t('subscriptions.inputs.enabled')"/>
          </v-col>

          <v-col cols="12">
            <AlertBar />
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
          class="mx-4"
          @submit="submit"
          @cancel="close" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import dialog from "@/mixins/dialog"
  import moment from "moment";
  export default {

    name: "SubscriptionsEditDialog",

    components: {

      AlertBar: () => import("@/components/Bars/AlertBar"),
      InputDatePicker: () => import("@/components/Inputs/InputDatePicker"),
      ButtonBarFormDialog: () => import("@/components/Buttons/ButtonBarFormDialog"),
      SelectCountries: () => import("@/components/Inputs/SelectCountries")
    },

    mixins: [dialog],

    data() {
      return {
        defaultForm:
        {
          enabled: true,
          subscription: {
            billing: {address: {}},
            dates: {}
          },
        }
      }
    },

    methods: {
      onInputDateStart(val) {
        this.form.subscription.dates.start = val;
      },

      onInputDateEnd(val) {
        this.form.subscription.dates.end = val;
      },

      onInputDateOccurrence(val) {
        this.form.subscription.dates.next_occurrence = val;
      },

      onChangePlan(val) {
        if (val === "FREE") {
          this.form.subscription.dates.next_occurrence = null;
          this.form.subscription.dates.end = moment().format("YYYY-MM-DD");
        }
        else {
          if (!this.form.subscription.dates.start)
            this.form.subscription.dates.start = moment().format("YYYY-MM-DD");

          if (val === "PREMIUM_WEEKLY")
            this.form.subscription.dates.next_occurrence = moment().add(1, "week").toISOString();
          else if (val === "PREMIUM_MONTHLY")
            this.form.subscription.dates.next_occurrence = moment().add(1, "month").toISOString();
          else if (val === "PREMIUM_ANNUALLY")
            this.form.subscription.dates.next_occurrence = moment().add(1, "year").toISOString();

        }
      }
    }
  }
</script>

<style scoped>

</style>